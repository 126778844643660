<div class="d-flex align-items-center">
  <h1 class="text-center" mat-dialog-title>Choose Publish Destinaton</h1>
  <button class="close-icon ms-auto" mat-icon-button [disableRipple]="true" (click)="close()">
    <mat-icon class="button-block_small-icon" svgIcon="close" />
  </button>
</div>

<div mat-dialog-content>
  <mat-chip-listbox class="mat-mdc-chip-set-stacked" multiple [(ngModel)]="selectedEnvironments">
    @for (env of ENVIRONMENTS; track env.id) {
      <mat-chip-option [value]="env.name">{{ env.name }}</mat-chip-option>
    }
  </mat-chip-listbox>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button class="button-success" id="1" mat-raised-button (click)="proceed()">
    <mat-icon [svgIcon]="'check'" />
    Publish
  </button>
</div>
