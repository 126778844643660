import { Component, effect, inject, Injector, OnDestroy, OnInit, Signal, signal, WritableSignal } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import {
  bulkUpdateProjectMatrixItemsStatuses,
  dispatchedCreateProject,
  getProjectMatrixItems,
  pendingCreateProject,
  selectCurrentProjectManageProject,
  selectCurrentProjectManageProjectMatrixCommentsState,
  selectCurrentProjectManageProjectMatrixItems,
  updateEntitiesStatuses,
  updateProject,
} from '../store/project.actions';
import { filter, map, take } from 'rxjs/operators';
import { ProjectModel } from '../project.model';
import { UtilsService } from '../../core/utils.service';
import { globalLoading } from '../../store/actions/ui.actions';
import { BaseComponent } from '../../shared/base.class';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Statuses } from '../../shared/models/statuses.enum';
import { getAssignsByProjectId, updateAssignments } from '../../assign/store/assign.actions';
import { loadProjectEventListByProjectId, updateProjectEvents } from '../../events/store/event.actions';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectAssignCommentsState, selectAssignListState } from '../../assign/store/assign.state';
import { selectEventList, selectEventsCommentsState } from '../../events/store/event.state';
import { Router } from '@angular/router';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import { AssignModel } from '../../assign/assign.model';
import { IStudyMatrixItem } from '../../study-matrix/study-matrix-editor/study-matrix-item.interface';
import { ProjectEventModel } from '../../events/project-event.model';
import { PublishDialogComponent } from './publish-dialog/publish-dialog.component';
import { IApprovalStep } from '../../shared/approval-step/approval-step.interface';

enum ErrorType {
  NotResolved = 'notResolved',
  NotInStatus = 'notInStatus',
  NoLength = 'noLength',
}

@Component({
  templateUrl: 'study-approvals.component.html',
  styleUrls: ['./study-approvals.component.scss'],
})
export class StudyApprovalsComponent extends BaseComponent implements OnInit, OnDestroy {
  injector: Injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  router: Router = inject(Router);
  utilsService: UtilsService = inject(UtilsService);
  assessments = toSignal(this.store.select(selectAssignListState));
  events = toSignal(
    this.store
      .select(selectEventList)
      .pipe(map((events: ProjectEventModel[]) => events.filter((event: ProjectEventModel) => !event.isBaseline))),
  );
  projectMatrixItems = toSignal(this.store.select(selectCurrentProjectManageProjectMatrixItems));

  assessmentsCommentsState = toSignal(this.store.select(selectAssignCommentsState), {
    injector: this.injector,
  });
  eventsCommentsState = toSignal(this.store.select(selectEventsCommentsState), { injector: this.injector });
  studyMatrixItemsCommentsState = toSignal(
    this.store.select(selectCurrentProjectManageProjectMatrixCommentsState, {
      inject: this.injector,
    }),
  );
  project: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });
  status$: Observable<Statuses> = this.store.select(selectCurrentProjectManageProject).pipe(
    map((project: ProjectModel) => {
      return project.projectStatus ?? Statuses.Draft;
    }),
  );
  // isLocked = toSignal(this.store.select(checkCurrentProjectLockedState), {
  //   injector: this.injector
  // });
  //
  isLocked = signal(false);

  //@TODO later this will lock the button according to some permissions

  releaseHistory: WritableSignal<IApprovalStep[]> = signal([
    // {
    //   id: 1,
    //   status: Statuses.Released,
    //   label: 'Released',
    //   active: false,
    //   done: true,
    //   icon: 'check',
    //   publishedTo: ['Production'],
    //   updatedAt: new Date().toString(),
    // },
    // {
    //   id: 2,
    //   status: Statuses.RevisionInProgress,
    //   label: 'Revision In Progress',
    //   active: true,
    //   done: false,
    //   icon: 'check',
    //   publishedTo: [],
    //   updatedAt: new Date().toString(),
    // },
  ]);
  constructor(
    private confirmDialog: PharConfirmDialogService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    const loadCommentsEffect = effect(
      () => {
        if (!this.project()?.id) {
          return;
        }

        this.store.dispatch(getAssignsByProjectId({ projectId: this.project().id }));
        this.store.dispatch(loadProjectEventListByProjectId({ id: this.project().id }));
        this.store.dispatch(getProjectMatrixItems({ projectId: this.project().id }));
        loadCommentsEffect.destroy();
      },
      {
        manualCleanup: true,
        injector: this.injector,
        allowSignalWrites: true,
      },
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  changeStatusTo(status: Statuses): void {
    this.store.dispatch(updateProject({ project: { ...this.project(), projectStatus: status } }));
  }

  returnToDraft(): void {
    this.changeStatusTo(Statuses.Draft);
    this.releaseHistory.set([]);
  }

  generateReleaseHistoryStep(
    status: Statuses,
    label: string,
    icon = 'check',
    done = false,
    active = true,
    successClass?: string,
  ): IApprovalStep {
    // return [
    //   {
    //     status: Statuses.Released,
    //     isCompleted: false,
    //     publishedTo: ['DEV', 'TEST'],
    //     updatedAt: new Date(),
    //   },
    //   {
    //     status: Statuses.RevisionInProgress,
    //     isCompleted: false,
    //     publishedTo: null,
    //     updatedAt: new Date(),
    //   },
    // ];
    return {
      id: new Date().getTime(),
      status: status,
      done,
      active,
      label,
      icon,
      successClass,
    };
  }

  handleStatusChange(event: { nextStatus: Statuses; step?: IApprovalStep }): void {
    switch (event.nextStatus) {
      case Statuses.PendingReview:
        this.toSendForReviewOrUnderReview(
          Statuses.ReadyForReview,
          Statuses.ReadyForReview,
          Statuses.ReadyForReview,
          false,
        );
        break;

      case Statuses.UnderReview:
        this.toSendForReviewOrUnderReview(
          Statuses.UnderReview,
          Statuses.ReadyForReview,
          Statuses.PendingApproval,
          true,
        );
        break;

      case Statuses.Released:
        // @TODO RELEASE STUDY WILL BE IMPLEMENTED IN THE FUTURE
        this.releaseStudy(event.step);
        break;

      case Statuses.Published:
        this.publishStudy(event.step);
        break;
      case Statuses.RevisionInProgress:
        this.startRevision(event.step);
        break;

      case Statuses.RevisionRejected:
        this.rejectRevision(event.step);
        break;

      case Statuses.RevisionCompleted:
        this.completeRevision(event.step);
        break;

      case Statuses.Rejected:
        this.rejectStudy();
        break;
    }
  }
  /*
   * After rejecting a Study, the Study goes to Rejected
   * All items in the study (assessments, events, matrix items) go to PendingRevision only if they are not Approved
   * All entities that are approved should be not touched (disabled/locked for editing)
   *    //@TODO all entities that are pending approval state should be available for Edit ( next status is Approved)
    //@TODO when you come from rejected state ( in draft or send for review ) check the rejection date because the validaitons are different
   */
  private rejectStudy(): void {
    // const entitiesNextStatus = Statuses.PendingRevision;
    this.bulkUpdateProjectEntities(Statuses.PendingRevision, Statuses.PendingApproval);
    // const assessmentsToUpdate: AssignModel[] = this.getEntitiesWithStatus(this.assessments(), Statuses.PendingApproval);
    // const eventsToUpdate: ProjectEventModel[] = this.getEntitiesWithStatus(this.events(), Statuses.PendingApproval);
    // const projectMatrixItemsToUpdate: IStudyMatrixItem[] = this.getEntitiesWithStatus(
    //   this.projectMatrixItems(),
    //   Statuses.PendingApproval,
    // );
    //
    // if (assessmentsToUpdate.length > 0) {
    //   this.store.dispatch(
    //     updateAssignments({
    //       assignments: assessmentsToUpdate.map((assign: AssignModel) => {
    //         return {
    //           ...assign,
    //           status: entitiesNextStatus,
    //         };
    //       }),
    //     }),
    //   );
    // }
    // if (eventsToUpdate.length > 0) {
    //   this.store.dispatch(
    //     updateProjectEvents({
    //       projectEvents: eventsToUpdate.map((projectEvent: ProjectEventModel) => ({
    //         ...projectEvent,
    //         status: entitiesNextStatus,
    //       })),
    //     }),
    //   );
    // }
    //
    // if (projectMatrixItemsToUpdate.length > 0) {
    //   this.store.dispatch(
    //     bulkUpdateProjectMatrixItemsStatuses({
    //       projectId: this.project().id,
    //       newStatus: entitiesNextStatus,
    //       ids: projectMatrixItemsToUpdate.map((item: IStudyMatrixItem) => item.id),
    //     }),
    //   );
    // }

    this.updateProject({ ...this.project(), projectStatus: Statuses.Rejected }, Statuses.Rejected);
  }

  private publishStudy(step: IApprovalStep): void {
    //@TODO Environments should be fetched from the DB and maybe something needs to be added here
    this.dialog
      .open(PublishDialogComponent, {
        width: '400px',
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: envs => {
          this.updateProject({ ...this.project(), projectStatus: Statuses.Published }, Statuses.Published);
          this.releaseHistory.update(data => {
            data = data.map(x => {
              if (x.id === step.id) {
                return {
                  ...x,
                  publishedTo: envs,
                  done: true,
                  updatedAt: new Date().toString(),
                };
              } else {
                return x;
              }
            });
            return [...data];
          });
        },
      });
  }
  private startRevision(step: IApprovalStep): void {
    this.confirmDialog
      .openConfirmDialog(`This will start the revision process, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.releaseHistory.update((data: IApprovalStep[]) => {
            //deactivate current step
            data = data.map(x => {
              if (x.id === step.id) {
                return {
                  ...x,
                  done: true,
                  active: false,
                };
              } else {
                return x;
              }
            });

            return [...data, this.generateReleaseHistoryStep(Statuses.RevisionInProgress, 'Revision In Progress')];
          });

          this.updateProject(
            { ...this.project(), projectStatus: Statuses.RevisionInProgress },
            Statuses.RevisionInProgress,
          );
        },
      });
  }

  private rejectRevision(step: IApprovalStep): void {
    this.confirmDialog
      .openConfirmDialog(`You are about to reject revision, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.releaseHistory.update((data: IApprovalStep[]) => {
            return [
              ...data.map(x => {
                if (x.id === step.id) {
                  return {
                    ...x,
                    done: true,
                    active: false,
                    updatedAt: new Date().toString(),
                  };
                } else {
                  return x;
                }
              }),
              this.generateReleaseHistoryStep(
                Statuses.RevisionRejected,
                'Revision Rejected',
                'close',
                true,
                true,
                'rejected',
              ),
            ];
          });
          this.updateProject(this.project(), Statuses.RevisionRejected);
        },
      });
  }

  private completeRevision(step: IApprovalStep): void {
    //@TODO Add validations here, all elements should be in approved state
    this.confirmDialog
      .openConfirmDialog(`You will complete the revision process, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.releaseHistory.update((data: IApprovalStep[]) => {
            return [
              ...data.map(x => {
                if (x.id === step.id) {
                  return {
                    ...x,
                    done: true,
                    updatedAt: new Date().toString(),
                  };
                } else {
                  return x;
                }
              }),
            ];
          });
          this.updateProject(this.project(), Statuses.RevisionCompleted);
        },
      });
  }
  private bulkUpdateProjectEntities(entitiesNextStatus: Statuses, filterStatus: Statuses): void {
    const assessmentsToUpdate: AssignModel[] = this.getEntitiesWithStatus(this.assessments(), filterStatus);
    const eventsToUpdate: ProjectEventModel[] = this.getEntitiesWithStatus(this.events(), filterStatus);
    const projectMatrixItemsToUpdate: IStudyMatrixItem[] = this.getEntitiesWithStatus(
      this.projectMatrixItems(),
      filterStatus,
    );

    if (assessmentsToUpdate.length > 0) {
      this.store.dispatch(
        updateAssignments({
          assignments: assessmentsToUpdate.map((assign: AssignModel) => {
            return {
              ...assign,
              status: entitiesNextStatus,
            };
          }),
        }),
      );
    }
    if (eventsToUpdate.length > 0) {
      this.store.dispatch(
        updateProjectEvents({
          projectEvents: eventsToUpdate.map((projectEvent: ProjectEventModel) => ({
            ...projectEvent,
            status: entitiesNextStatus,
          })),
        }),
      );
    }

    if (projectMatrixItemsToUpdate.length > 0) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(
        bulkUpdateProjectMatrixItemsStatuses({
          projectId: this.project().id,
          newStatus: entitiesNextStatus,
          ids: projectMatrixItemsToUpdate.map((item: IStudyMatrixItem) => item.id),
        }),
      );
    }
  }

  private releaseStudy(step: IApprovalStep): void {
    //@TODO Add validations here if its needed and some more actions
    // const defaultVersion = '0.1';
    // const nextVersion = this.utilsService.incrementVersion(this.project().projectVersion ?? defaultVersion, 'major');
    // this.confirmDialog
    //   .openConfirmDialog(`Have all reviewers approved the Study?`, `${this.project().name}`, 'Yes', 'No')
    //   .pipe(
    //     filter(isConfirmed => !!isConfirmed),
    //     switchMap(() =>
    //       this.confirmDialog.openConfirmDialog(
    //         `Do you want to update the version number to ${nextVersion}?`,
    //         `${this.project().name} V${this.project().projectVersion ?? defaultVersion}`,
    //         'Yes',
    //         'No',
    //       ),
    //     ),
    //     switchMap(isConfirmed => {
    //       if (isConfirmed) {
    //         return of(nextVersion);
    //       }
    //
    //       //call the real update
    //       this.updateProject({ ...this.project(), projectVersion: nextVersion }, Statuses.Released);
    //
    //       return this.dialog
    //         .open(VersionInputDialogComponent, {
    //           width: '300px',
    //           data: {
    //             title: `${this.project().name} V${this.project().projectVersion ?? defaultVersion}`,
    //             defaultVersion: this.project().projectVersion ?? defaultVersion,
    //           },
    //         })
    //         .afterClosed();
    //     }),
    //     tap(version => {
    //       if (version) {
    //         this.updateProject({ ...this.project(), projectVersion: version }, Statuses.Released);
    //       }
    //     }),
    //     switchMap(() => this.status$), // change the flow to get wait for status change
    //     filter(status => status === Statuses.Released), // filter to get the updates status only
    //     tap(() => {
    //       this.confirmDialog.openConfirmDialog('', `${this.project().name} is now released`, 'Got it', '');
    //     }),
    //     take(1),
    //   )
    //   .subscribe();
    this.store.dispatch(updateProject({ project: { ...this.project(), projectStatus: Statuses.Released } }));

    ///@TODO here we should call the release history api endpoint
    // first release
    this.releaseHistory.update(data => {
      let modified = data;
      if (step) {
        modified = modified.map(x => {
          if (x.id === step.id) {
            return {
              ...x,
              done: true,
              active: false,
            };
          } else {
            return x;
          }
        });
      }
      return [...modified, this.generateReleaseHistoryStep(Statuses.Released, 'Released')];
    });
  }

  /*
   * After study is send for review the Study goes to UnderReview, items goes to PendingApproval
   */
  private toSendForReviewOrUnderReview(
    projectNextStatus: Statuses,
    entitiesValidationStatus: Statuses,
    entitiesNextStatus: Statuses,
    updateEntities: boolean,
  ): void {
    const entityErrors: {
      url: string;
      title: string;
      errorType: ErrorType;
    } | null = this.validateStudyEntities(entitiesValidationStatus);

    if (entityErrors) {
      this.confirmDialog
        .openConfirmDialog(
          `<b>${entityErrors.title}</b> - ${this.getErrorMessage(entityErrors.errorType, entitiesNextStatus)}`,
          'Warning',
          'Go there',
          'Cancel',
        )
        .pipe(
          filter(res => res),
          take(1),
        )
        .subscribe({
          next: () => {
            this.router.navigate([`dashboard/study/edit/${this.project().id}/${entityErrors.url}`]);
          },
        });

      return;
    }

    this.confirmDialog
      .openConfirmDialog(
        `Are you sure you want to proceed? The status of the study will be updated to <b>${this.utilsService.getFormattedStatus(projectNextStatus)}</b>`,
        'Please Confirm',
      )
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(updateProject({ project: { ...this.project(), projectStatus: projectNextStatus } }));
          if (updateEntities) {
            if (this.project().rejectionDate) {
              this.bulkUpdateProjectEntities(entitiesNextStatus, Statuses.ReadyForReview);
              return;
            }
            this.store.dispatch(
              updateEntitiesStatuses({
                projectId: this.project().id,
                newStatus: entitiesNextStatus,
              }),
            );
          }
        },
      });
  }

  private getDateField(projectStatus: Statuses): Record<string, string> | null {
    const date = new Date().toJSON();
    switch (projectStatus) {
      case Statuses.PendingReview:
        return { dateSentForReview: date };
      case Statuses.Rejected:
        return { rejectionDate: date };
      case Statuses.UnderReview:
        return { dateReviewStarted: date };
      case Statuses.Released:
        return { releaseDate: date };
      case Statuses.Amended:
        return { amendedDate: date };
      default:
        return null;
    }
  }

  private updateProject(projectData: ProjectModel, projectStatus: Statuses): void {
    const dateField = this.getDateField(projectStatus);
    let project = {
      ...projectData,
      projectStatus,
    };

    if (dateField) {
      project = {
        ...project,
        ...dateField,
      };
    }
    this.utilsService.dispatchActions(this.store, [
      globalLoading(true),
      pendingCreateProject({ pending: true }),
      dispatchedCreateProject({ dispatched: true }),
      updateProject({ project }),
    ]);
  }

  validateStudyEntities(status: Statuses): { title: string; url: string; errorType: ErrorType } | null {
    // @TODO revert this
    return null;
    const assessmentsError = this.checkEntity(
      Object.values(this.assessmentsCommentsState()),
      this.assessments(),
      status,
    );

    if (assessmentsError) {
      return { title: 'Assessments', url: 'assessments', errorType: assessmentsError };
    }

    const projectEventsError = this.checkEntity(Object.values(this.eventsCommentsState()), this.events(), status);
    if (projectEventsError) {
      return { title: 'Events', url: 'events', errorType: projectEventsError };
    }

    const studyMatrixItemsError = this.checkEntity(
      Object.values(this.studyMatrixItemsCommentsState()),
      this.projectMatrixItems(),
      status,
    );

    if (studyMatrixItemsError) {
      return { title: 'Schedule of assessments', url: 'schedule-assessments', errorType: studyMatrixItemsError };
    }

    return null;
  }

  /**
   * Check entity if it's ready for the next status
   * its return ErrorType if fails and null if everything is ok
   * @param comments
   * @param entities
   * @param status
   * @private
   */
  private checkEntity<
    T extends {
      status: Statuses;
    },
  >(comments: ICommentsState[], entities: T[], status: Statuses): ErrorType | null {
    if (comments.some(state => !state.isAllResolved)) return ErrorType.NotResolved;
    if (entities.length === 0) return ErrorType.NoLength;
    if (entities.filter(item => item.status !== Statuses.Approved).some(item => item.status !== status))
      return ErrorType.NotInStatus;

    return null;
  }

  private getErrorMessage(errorType: ErrorType, entitiesNextStatus: Statuses): string {
    switch (errorType) {
      case ErrorType.NotResolved:
        return 'All comments must be resolved.';
      case ErrorType.NotInStatus:
        return `All statuses must be in <b>${this.utilsService.getFormattedStatus(entitiesNextStatus)}</b> status`;
      case ErrorType.NoLength:
        return 'Must have at least one record.';
    }
  }

  getEntitiesWithStatus<T extends { status: Statuses }>(entities: T[], status: Statuses): T[] {
    return entities.filter(item => item.status === status);
  }

  protected readonly Statuses = Statuses;
}
