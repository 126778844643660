<div
  class="list-page-cards scroll-holder list-page-cards--no-header scroll-holder--inSubContext mb-3"
  id="assign-list"
  [class.scroll-holder--no-header]="!!!(header$ | async)">
  <div class="d-flex">
    @if (!editLocked() && !actionsLocked()) {
      <phar-study-assessments
        [listName]="assessmentsContainer"
        [connectedTo]="draggableListViewComponent?.dls || []"
        (assign)="assignForm($event)" />
    }
    <div class="px-3 flex-grow mt-2" id="assign-list-container">
      @if (!editLocked()) {
        <div class="d-flex justify-content-end">
          <button class="ms-auto" mat-stroked-button (click)="addGroup()">
            <mat-icon svgIcon="plus" />
            Add Group
          </button>
        </div>
      }

      <div class="mb-2 mt-3 w-100 d-flex justify-content-between">
        <phar-list-filter
          [searchField]="'formName'"
          [activeFilters]="lisFilterManager.filters$ | async"
          [displayedFilterOptions]="filterableFields"
          (filterAdd)="handleFilterChange($event)"
          (filterUpdate)="handleFilterUpdate($event)" />
      </div>

      @if (listView(); as listView) {
        <phar-draggable-list-view
          #draggableListViewComponent
          [connectedListId]="assessmentsContainer"
          [idClassMap]="idClassMap()"
          [groupClass]="'assign-group-background'"
          [config]="config"
          [dataSource]="listView"
          [groupFieldValue]="AssignType.AssignGroupEntity"
          [groupField]="'assignmentType'"
          [draggingDisabled]="editLocked() || isLoading()"
          [orderField]="'orderPriority'"
          [initiallyExpandedItems]="initiallyExpandedItems()"
          (elementAdded)="handleElementAdded($event)"
          (afterActionClicked)="actionHandler($event)"
          (afterReorder)="onReorder($event)" />
      }
    </div>

    @if ((groupEditorOpened$ | async) && !editLocked()) {
      <div>
        <phar-assign-group-editor
          [assignment]="selectedAssignment"
          (discard)="closeGroupEditor()"
          (save)="saveGroup($event)" />
      </div>
    }
  </div>
</div>
<ng-template #createdAtTemplate let-element>
  {{ element.createdAt | date: 'd MMM y' }}
</ng-template>

<ng-template #dragIconTemplate let-element>
  @if (!element.draggingDisabled()) {
    <button cdkDragHandle class="cursor-move" draggable="true" mat-icon-button [disableRipple]="true">
      <mat-icon class="button-block_small-icon" svgIcon="handle" />
    </button>
  }
</ng-template>

<ng-template #expandIconTemplate let-element>
  @if (element.type === FieldType.Group) {
    <button
      class="expand-collapse-trigger d-flex justify-content-center ms-2 p-0"
      mat-icon-button
      [disableRipple]="true">
      @if (element.isExpanded) {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-down" />
      } @else {
        <mat-icon class="button-block_small-icon small-icon" svgIcon="arrow-right" />
      }
    </button>
  }
</ng-template>

<ng-template #formNameTemplate let-element>
  @if (element.type === FieldType.Group) {
    {{ element.groupName }}
  } @else {
    {{ element.formName }}
  }
</ng-template>

<ng-template #actionsTemplate let-element>
  <button
    class="ms-auto list-card_more me-2"
    mat-icon-button
    [matMenuTriggerFor]="actionsMenu"
    (click)="$event.stopImmediatePropagation()">
    <mat-icon class="more">more_vert</mat-icon>
  </button>

  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    <ng-template matMenuContent>
      @let additionalLock = projectHasBeenRejected() && element.status === Statuses.Approved;
      @if (!element.formId) {
        <button
          class="button-with-icon me-2"
          mat-menu-item
          [disabled]="editLocked()"
          (click)="actionHandler({ eventName: AssignItemActions.Edit, dataItem: element })">
          <mat-icon svgIcon="edit" />
          <span>Edit</span> {{ !element.formId }}
        </button>
      }

      <button
        class="button-with-icon me-2"
        mat-menu-item
        (click)="actionHandler({ eventName: AssignItemActions.Preview, dataItem: element })">
        <mat-icon svgIcon="eye" />
        <span>Preview</span>
      </button>

      <button
        class="button-with-icon me-2"
        mat-menu-item
        [disabled]="actionsLocked() || additionalLock"
        (click)="actionHandler({ eventName: AssignItemActions.Comments, dataItem: element })">
        <mat-icon svgIcon="comment" />
        <span>Comments</span> ({{ commentsState()[element.id]?.commentCount || 0 }})
      </button>

      <button
        class="button-with-icon me-2"
        mat-menu-item
        [disabled]="editLocked() || additionalLock || element.status === nextStatus()"
        (click)="actionHandler({ eventName: AssignItemActions.Delete, dataItem: element })">
        <mat-icon svgIcon="trash" />
        <span>Delete</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-template>

<ng-template #assignmentTypeTemplate let-element>
  <div class="d-flex align-items-center">
    @switch (element.assignmentType) {
      @case (AssignType.AssignGroupEntity) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="folder" />
        <span>Group</span>
      }
      @case (AssignType.Questionnaire) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms" />
        <span>eClinRO</span>
      }
      @case (AssignType.EObsRO) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms" />
        <span>eObsRo</span>
      }
      @case (AssignType.DataCapture) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms" />
        <span>ePRO</span>
      }
      @case (AssignType.InformedConsent) {
        <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="forms" />
        <span>Informed Consent</span>
      }
    }
  </div>
</ng-template>

<ng-template #versionTemplate let-element>
  {{ element.form?.version || '-' }}
</ng-template>
<ng-template #commentsTemplate let-element>
  @let additionalLock = projectHasBeenRejected() && element.status === Statuses.Approved;
  <button mat-icon-button [disableRipple]="true" [disabled]="actionsLocked() || additionalLock">
    <mat-icon
      pharNoPropagation
      [svgIcon]="'comment'"
      [pharTooltip]="'Comments (' + (commentsState()[element.id]?.commentCount ?? 0) + ')'"
      [ngClass]="{
        'icon-red': commentsState()[element.id] && !commentsState()[element.id].isAllResolved,
        'icon-blue': commentsState()[element.id] && commentsState()[element.id]?.isAllResolved,
      }"
      (click)="actionHandler({ eventName: EventActions.Comments, dataItem: element })" />
  </button>
</ng-template>

<ng-template #statusTemplate let-element>
  <phar-status-badge [status]="element.status || Statuses.PendingReview" />
</ng-template>
<ng-template #changeStatusTemplate let-element>
  @let additionalLock = projectHasBeenRejected() && element.status === Statuses.Approved;
  <mat-slide-toggle
    #el
    color="primary"
    pharNoPropagation
    [hideIcon]="true"
    [checked]="element.status === nextStatus() || additionalLock"
    [disabled]="actionsLocked() || additionalLock"
    (click)="itemStatusChange(element, el)" />
</ng-template>
<ng-template #changeStatusHeaderTemplate>
  @if (listViewRaw().length > 0) {
    @if (actionsLocked() || projectHasBeenRejected()) {
      <span class="header-with-menu"> Confirm all </span>
    } @else {
      <div class="header-with-menu" [matMenuTriggerFor]="actionsMenu" (click)="$event.stopImmediatePropagation()">
        Confirm All
        @if (!actionsLocked()) {
          <mat-icon class="more">more_vert</mat-icon>
        }
      </div>
    }
    <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
      <div mat-menu-item>
        <mat-slide-toggle
          #el
          color="primary"
          class="w-100"
          [hideIcon]="true"
          [checked]="allAssessmentsStatusOk()"
          (click)="handleBulkStatusChange(el)"
          >Change all statuses
        </mat-slide-toggle>
      </div>
    </mat-menu>
  }
</ng-template>
