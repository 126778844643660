import {
  Component,
  computed,
  effect,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  Signal,
  signal,
} from '@angular/core';
import { ProjectModel } from '../../project.model';
import { Statuses } from '../../../shared/models/statuses.enum';
import { DatePipe, JsonPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { IApprovalStep } from '../../../shared/approval-step/approval-step.interface';
import { ApprovalStepComponent } from '../../../shared/approval-step/approval-step.component';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../../../shared/phar-button/phar-button.component';

@Component({
  selector: 'phar-study-approval-steps',
  templateUrl: 'study-approval-steps.component.html',
  styleUrls: ['study-approval-steps.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, ApprovalStepComponent, DatePipe, MatButton, MatIcon, NgClass, ButtonComponent, JsonPipe],
})
export class StudyApprovalStepsComponent {
  study: InputSignal<ProjectModel> = input.required<ProjectModel>();
  releaseHistory: InputSignal<any[]> = input.required<any[]>();
  requestStatusChange: OutputEmitterRef<{ nextStatus: Statuses; step?: IApprovalStep }> = output<{
    nextStatus: Statuses;
    step?: IApprovalStep;
  }>();
  currentDate = new Date(); //@TODO to be removed
  STATUSES_WITHOUT_RELEASE_STEP = [
    Statuses.Published,
    Statuses.RevisionInProgress,
    Statuses.RevisionRejected,
    Statuses.RevisionCompleted,
  ];
  currentStatus: Signal<Statuses> = computed(() => {
    return this.study()?.projectStatus;
  });
  REJECTED_STATUS: IApprovalStep = {
    status: Statuses.Rejected,
    label: 'Rejected',
    done: false,
    active: true,
    icon: 'close',
    successClass: 'rejected',
  };
  RELEASED_STATUS: IApprovalStep = {
    status: Statuses.Released,
    label: 'Released',
    done: false,
    active: true,
    icon: 'check',
  };

  statusMap: Partial<Record<Statuses, IApprovalStep>> = {
    [Statuses.Draft]: { status: Statuses.Draft, label: 'Draft', done: false, active: false },
    [Statuses.ReadyForReview]: {
      status: Statuses.ReadyForReview,
      label: 'Sent for review',
      done: false,
      active: false,
    },
    [Statuses.UnderReview]: { status: Statuses.UnderReview, label: 'Under Review', done: false, active: false },
    [Statuses.Released]: { status: Statuses.Released, label: 'Released', done: false, active: false },
  };


  steps = signal<IApprovalStep[]>([]);

  constructor() {
    effect(
      () => {
        if (this.currentStatus()) {
          this.steps.set(this.generateSteps(this.currentStatus()));
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private generateSteps(status: Statuses): IApprovalStep[] {
    let statusMapClone = { ...this.statusMap };
    if (status === Statuses.Rejected) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete statusMapClone[Statuses.Released];

      statusMapClone = {
        ...statusMapClone,
        [Statuses.Rejected]: this.REJECTED_STATUS,
      };
    }
    const currentStatusIndex = Object.keys(statusMapClone).indexOf(status);
    const currentStep: IApprovalStep = statusMapClone[status];

    if (!currentStep) {
      // if the step is not in the status map we should mark all as done
      Object.values(statusMapClone).forEach(step => {
        step.done = true;
        step.active = false;
      });

      if (this.STATUSES_WITHOUT_RELEASE_STEP.includes(status)) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete statusMapClone[Statuses.Released];
      }

      return Object.values(statusMapClone);
    }
    const forceDone = currentStep.status === Statuses.Rejected || currentStep.status === Statuses.Released;
    Object.values(statusMapClone).forEach((step, index) => {
      step.done = index < currentStatusIndex || forceDone;
      step.active = index === currentStatusIndex;
    });

    if (currentStep.status === Statuses.Released) {
      // if status is released, we should show the release history
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete statusMapClone[Statuses.Released];
    }

    return Object.values(statusMapClone);
  }

  protected readonly Statuses = Statuses;
}
