<div id="steps-container" class="d-flex flex-column">
  @for (step of steps(); let i = $index; track step.status) {
    <phar-approval-step [index]="i + 1" [step]="step">
      <ng-template [ngTemplateOutletContext]="{ $implicit: step }" [ngTemplateOutlet]="actionsTemplate" />
    </phar-approval-step>
  }
  @if (releaseHistory().length > 0) {
    @for (step of releaseHistory(); track $index) {
      <phar-approval-step [step]="step">
        <ng-template
          [ngTemplateOutletContext]="{ $implicit: step, history: true }"
          [ngTemplateOutlet]="actionsTemplate" />
      </phar-approval-step>
    }
  }
</div>

<ng-template #actionsTemplate let-step let-history="history">
  <div class="step-content w-100 d-flex align-items-center">
    @switch (step.status) {
      @case (Statuses.Draft) {
        <div class="step-body">
          <ng-template [ngTemplateOutletContext]="{ $implicit: study().createdAt }" [ngTemplateOutlet]="dateTemplate" />
        </div>
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{ nextStatus: Statuses.PendingReview, label: 'Send for review', icon: 'send' }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.ReadyForReview) {
        @if (step.done) {
          <div class="step-body">
            <ng-template
              [ngTemplateOutletContext]="{ $implicit: study().dateSentForReview || currentDate }"
              [ngTemplateOutlet]="dateTemplate" />
          </div>
        }
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.UnderReview,
                label: 'Start review',
                icon: 'visibility',
              }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.UnderReview) {
        @if (step.done) {
          <div class="step-body">
            <ng-template
              [ngTemplateOutletContext]="{ $implicit: study().dateReviewStarted || currentDate }"
              [ngTemplateOutlet]="dateTemplate" />
          </div>
        }
        @if (step.active && !step.done) {
          <div class="step-actions ms-auto">
            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.Rejected,
                label: 'Reject',
                icon: 'close',
                type: 'warn',
                additionalClass: 'me-2',
              }"
              [ngTemplateOutlet]="buttonTemplate" />

            <ng-template
              [ngTemplateOutletContext]="{
                nextStatus: Statuses.Released,
                label: 'Release',
                icon: 'check',
              }"
              [ngTemplateOutlet]="buttonTemplate" />
          </div>
        }
      }
      @case (Statuses.Rejected) {
        @if (step.done) {
          <div class="step-body">
            <ng-template
              [ngTemplateOutletContext]="{ $implicit: study().rejectionDate }"
              [ngTemplateOutlet]="dateTemplate" />
          </div>
        }
      }
      @case (Statuses.Released) {
        @if (history) {
          <div class="step-body me-auto d-flex align-items-center">
            <ng-template
              [ngTemplateOutletContext]="{ $implicit: study().releaseDate || currentDate }"
              [ngTemplateOutlet]="dateTemplate" />
            <span class="fw-bold ms-1"> V{{ study().projectVersion }}</span>
            @if (step.publishedTo && step.publishedTo.length > 0) {
              <span class="published-to ms-3">Published</span>
              <div class="ms-3">
                {{ step.publishedTo.join() }}
              </div>
            }
          </div>
          @if (step.active) {
            <div class="step-actions">
              <phar-button
                [state]="'warning'"
                [disabled]="!step.done"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionInProgress, step })">
                Start Revision
              </phar-button>
              <phar-button
                [disabled]="step.done"
                [state]="'success'"
                [class]="'ms-2'"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.Published, step })">
                Publish
              </phar-button>
            </div>
          }
        }
      }
      @case (Statuses.RevisionInProgress) {
        <div class="step-body me-auto d-flex align-items-center">
          <ng-template
            [ngTemplateOutletContext]="{ $implicit: study().releaseDate || currentDate }"
            [ngTemplateOutlet]="dateTemplate" />
        </div>
        @if (step.active) {
          <div class="step-actions">
            @if (step.done) {
              <phar-button
                [disabled]="!step.done"
                [state]="'warn'"
                [class]="'ms-2'"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionRejected, step })">
                REJECT REVISION
              </phar-button>

              <phar-button
                [disabled]="!step.done"
                [state]="'success'"
                [class]="'ms-2'"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.Released, step })">
                RELEASE
              </phar-button>
            } @else {
              <phar-button
                [state]="'success'"
                [disabled]="step.done"
                (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionCompleted, step })">
                REVISION COMPLETED
              </phar-button>
            }
          </div>
        }
      }

      @case (Statuses.RevisionRejected) {
        <div class="step-body me-auto d-flex align-items-center">
          <ng-template [ngTemplateOutletContext]="{ $implicit: step.updatedAt }" [ngTemplateOutlet]="dateTemplate" />
        </div>

        @if (step.active) {
          <div class="step-actions">
            <phar-button
              [state]="'success'"
              (btnClicked)="requestStatusChange.emit({ nextStatus: Statuses.RevisionInProgress, step })">
              RETURN TO REVISION
            </phar-button>
          </div>
        }
      }
    }
  </div>
</ng-template>

<ng-template #dateTemplate let-date>
  {{ date | date: 'd MMM y' }}
</ng-template>

<ng-template
  #buttonTemplate
  let-nextStatus="nextStatus"
  let-label="label"
  let-icon="icon"
  let-type="type"
  let-additionalClass="additionalClass">
  @let btnState = type || 'success';
  <phar-button
    [state]="btnState"
    [class]="additionalClass || ''"
    (btnClicked)="requestStatusChange.emit({ nextStatus })">
    <mat-icon [svgIcon]="icon" />
    {{ label }}
  </phar-button>
</ng-template>
