<div id="events" class="h-100">
  <phar-scheduled-events-list
    #scheduledEventsListComponent
    [editLocked]="editLocked()"
    [actionsLocked]="actionsLocked()"
    (duplicateEvent)="duplicateEvent($event)">
    <ng-template [ngTemplateOutlet]="switchEventsListTmp" />
  </phar-scheduled-events-list>
  <!--@if (selectedTab === EventTab.ScheduledEvents) {
    <phar-scheduled-events-list
      #scheduledEventsListComponent
      [editLocked]="editLocked()"
      [actionsLocked]="actionsLocked()"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp" />
    </phar-scheduled-events-list>
  } @else {
    <phar-adhoc-events-list
      #adhocEventsListComponent
      [editLocked]="editLocked()"
      [actionsLocked]="actionsLocked()"
      (duplicateEvent)="duplicateEvent($event)">
      <ng-template [ngTemplateOutlet]="switchEventsListTmp" />
    </phar-adhoc-events-list>
  }-->
</div>

<ng-template #switchEventsListTmp>
  <div class="d-flex align-items-center pt-3 px-4">
    <!--<div class="ms-auto">
      <phar-toggle-buttons [initialValue]="selectedTab" [elements]="tabs" (selectionChange)="setActiveTab($event)" />
    </div>-->
    <div class="ms-auto">
      @if (!editLocked()) {
        <button class="ms-auto btn-small" mat-raised-button (click)="add()">
          <mat-icon svgIcon="plus" />
          <b>Add</b>
        </button>
      }
    </div>
  </div>
</ng-template>
