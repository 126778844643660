import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { StudyTeamMemberFormComponent } from '../../sites-and-study-team/study-and-study-team-manage/study-team-member-form/study-team-member-form.component';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';

@Component({
  templateUrl: 'publish-dialog.component.html',
  styleUrl: 'publish-dialog.component.scss',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    StudyTeamMemberFormComponent,
    MatChipListbox,
    MatChipOption,
    FormsModule,
  ],
})
export class PublishDialogComponent {
  constructor(public dialogRef: MatDialogRef<PublishDialogComponent>) {}
  selectedEnvironments = [];
  ENVIRONMENTS = [
    { id: 1, name: 'DEV' },
    { id: 2, name: 'TEST' },
    { id: 3, name: 'STAGE' },
    { id: 4, name: 'DEMO/TRAIN' },
    { id: 5, name: 'VAL' },
    { id: 6, name: 'PROD' },
  ];
  close(): void {
    this.dialogRef.close();
  }

  proceed(): void {
    this.dialogRef.close(this.selectedEnvironments);
  }
}
